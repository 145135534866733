import 'aos/dist/aos.css';
import { TFunction } from 'i18next';
import { SetStateAction, useState } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/swiper.min.css';
import { userinfoAtom } from '../../store';
import HomeButton from '../common/HomeButtons';
import { LeftArrow, RightArrow } from './Arrows';

interface SubSectionPropType {
  subTitle:
    | '국내 유일 옴니채널 인공지능 비서'
    | '생성형 인공지능 영상생성과 방송 플랫폼'
    | '누구나 손쉽게 생성가능한 맞춤형 AI 가상인간'
    | '누구나 손쉽게 생성가능한 또 다른 나';
  title: '플루닛 워크센터' | '플루닛 스튜디오' | '플루닛 메타휴먼';
  sectionButtonArray: {
    buttonText: string;
    phrase: string;
    secondPhrase?: string;
    icon: string;
    selectedIcon: string;
  }[];
  buttonText: string;
  routeUrl: '/work-center' | '/studio' | '/metahuman' | '/signup' | '/son';
  secondButtonRouter: string;
  vector: string;
  buttonLink?: string;
  secondVector?: string;
  mobileVector: string;
  section?: 'workcenter' | 'studio' | 'metahuman' | 'son';
  handleQuickStart: () => void;
}

const inactiveStyle =
  'text-white text-xl max-2xl:text-lg max-md:text-base text-opacity-[0.4] hover:text-opacity-[0.6] hover:border-opacity-[0.4] duration-100 ease-out';
const activeStyle = 'text-transparent text-xl max-2xl:text-lg max-md:text-base';

export default function MainImageSection({
  subTitle,
  title,
  sectionButtonArray,
  buttonText,
  buttonLink,
  section,
  routeUrl,
  handleQuickStart,
}: SubSectionPropType) {
  SwiperCore.use([Navigation, Pagination]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [text, setText] = useState<{
    first: string;
    second?: string;
  }>({
    first: sectionButtonArray ? sectionButtonArray[0].phrase : '',
    second: sectionButtonArray ? sectionButtonArray[0]?.secondPhrase : '',
  });

  const getFirstDescription = () => {
    switch (title) {
      case t('플루닛 워크센터'):
        return t('24시간 365일 쉬지 않는 AI 직원이 당신을 위해 일합니다.');
      case t('플루닛 스튜디오'):
        return t('나와 똑닮은 당신만의 가상인간으로 다양한 영상을 제작하고');
      case t('플루닛 메타휴먼'):
        return t('몇 번의 클릭만으로 인간처럼 말하고, 보고, 행동하는');
      case t('모바일앱 손비서'):
        return t('놓치는 전화 없이 모든 전화를 대신 받아주고,');
    }
  };
  const getSecondDescription = () => {
    switch (title) {
      case t('플루닛 워크센터'):
        return t('지금 바로 플루닛의 인공지능 직원을 채용해보세요.');
      case t('플루닛 스튜디오'):
        return t(
          '손쉽게 프레젠테이션, 개인방송 또는 뉴스까지 제작하며 스트리밍하세요.'
        );
      case t('플루닛 메타휴먼'):
        return t('자연스러운 메타휴먼을 비즈니스 니즈에 맞춰 사용해보세요.');
      case t('모바일앱 손비서'):
        return t(
          '메모 전달도 아이폰 통화녹음도 가능한 나만의 AI비서를 경험해보세요.'
        );
    }
  };

  return (
    <section className="bg-black max-md:pl-5 pt-[100px] max-md:pt-0">
      <div className="max-w-[1600px] max-2xl:max-w-[1200px] w-full h-full mx-auto z-10 flex items-center max-md:flex-col-reverse">
        <div className="w-full max-2xl:w-[80%] max-md:w-full flex items-center swiper-animation">
          <div className="w-full max-md:mt-[4.5rem]">
            <h2 className="font-bold text-transparent text-[32px] max-md:text-xl bg-clip-text bg-gradient-to-r from-[#FF4E83] to-[#FFBB54] whitespace-pre-wrap">
              {t(subTitle)}
            </h2>
            <h1 className="font-bold text-[80px] max-2xl:text-7xl max-md:text-4xl text-white max-2xl:mt-3 max-md:mt-[0.25rem]">
              {t(title)}
            </h1>
            <p className="text-white w-full opacity-[0.6] text-2xl leading-9 max-2xl:text-xl max-md:text-base mt-8 max-md:mt-4 font-light max-md:w-full max-md:pr-10 whitespace-pre-wrap">
              {getFirstDescription()}
              <br className="max-md:hidden" />
              {getSecondDescription()}
            </p>

            <div className="flex flex-col font-extralight max-md:pr-0 max-2xl:w-3/4 max-md:w-full">
              <MobileButtonSection
                sectionButtonArray={sectionButtonArray}
                setText={setText}
                text={text}
                t={t}
              />
              <div className="max-md:hidden text-white max-md:space-x-1 flex max-md:overflow-x-scroll inquiry-detail max-md:flex-nowrap flex-wrap mt-10 mb-4">
                <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                  {sectionButtonArray.map(
                    (
                      { buttonText, phrase, secondPhrase, icon, selectedIcon },
                      idx
                    ) => (
                      <div
                        className={`w-fit whitespace-nowrap bg-gradient-to-r mr-3 max-md:mr-2 flex items-center justify-center text-center p-[1px] rounded-full cursor-pointer ${
                          phrase === text.first
                            ? 'from-[#FF4E83] to-[#FFBB54]'
                            : 'from-white/20 to-white/20 hover:from-white/40 hover:to-white/40'
                        }`}
                        key={`button-${idx}`}
                      >
                        <div
                          onClick={() =>
                            setText({
                              first: phrase,
                              second: secondPhrase,
                            })
                          }
                          className={`${
                            phrase === text.first ? activeStyle : inactiveStyle
                          }  px-5 py-3 max-2xl:px-4 max-2xl:py-2 max-md:px-3 max-md:py-2 flex items-center space-x-2 bg-black w-full h-full rounded-full`}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${
                                phrase === text.first ? selectedIcon : icon
                              })`,
                            }}
                            className="w-6 h-6 bg-center bg-no-repeat bg-contain"
                          />
                          <span
                            className={`max-md:truncate ${
                              phrase === text.first
                                ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#FF4E83] to-[#FFBB54]'
                                : 'text-white/50 group-hover:text-white/70'
                            } `}
                          >
                            {t(buttonText)}
                          </span>
                        </div>
                      </div>
                    )
                  )}
                </ScrollMenu>
              </div>
              <div className="flex flex-col h-14 max-md:h-6">
                <span className="text-white text-xl max-2xl:text-lg max-md:text-sm 2xl:whitespace-pre-wrap">
                  {t(text.first)}
                </span>
                {text.second && (
                  <span className="text-white text-xl max-2xl:text-lg max-md:text-sm">
                    {t(text.second)}
                  </span>
                )}
              </div>
            </div>

            <div className="mt-14 space-x-5 flex max-md:space-x-3 max-md:mt-12">
              {buttonText === '나만의 메타휴먼 만들기' ||
              buttonText === 'Create My Own Metahuman' ? null : (
                <HomeButton
                  onClick={() => {
                    if (
                      buttonText === '베타 테스터 신청하기' ||
                      buttonText === 'Apply to Beta Tester'
                    ) {
                      alert('테스터 모집이 마감되었습니다.');
                      return;
                    }
                    if (
                      buttonText === '무료시작하기' ||
                      buttonText === 'Get start now'
                    ) {
                      handleQuickStart();
                      return;
                    }
                    if (buttonLink && buttonLink.includes('https')) {
                      window.open(buttonLink);
                    } else {
                      buttonLink && navigate(buttonLink);
                    }
                  }}
                  icon={true}
                >
                  {t(buttonText)}
                </HomeButton>
              )}
              <Link to={routeUrl}>
                <div className="p-[1px] bg-gradient-to-br from-white to-white rounded-lg hover:from-primary hover:to-primaryTo">
                  <HomeButton icon={true} color="main white">
                    {t('더 알아보기')}
                  </HomeButton>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {section === 'workcenter' ? (
          <WorkCenterImageSection />
        ) : section === 'studio' ? (
          <StudioImageSection />
        ) : section === 'metahuman' ? (
          <MetahumanImageSection />
        ) : (
          <SonImageSection />
        )}
      </div>
    </section>
  );
}

function MobileButtonSection({
  sectionButtonArray,
  setText,
  text,
  t,
}: {
  sectionButtonArray: {
    buttonText: string;
    phrase: string;
    secondPhrase?: string | undefined;
    icon: string;
    selectedIcon: string;
  }[];
  text: {
    first: string;
    second?: string | undefined;
  };
  setText: (
    value: SetStateAction<{
      first: string;
      second?: string | undefined;
    }>
  ) => void;
  t: TFunction;
}) {
  return (
    <div className="md:hidden text-white space-x-2 flex overflow-x-scroll inquiry-detail flex-nowrap mt-6 mb-3 pr-5">
      {sectionButtonArray.map(
        ({ buttonText, phrase, secondPhrase, icon, selectedIcon }, idx) => (
          <div
            className={`w-fit whitespace-nowrap bg-gradient-to-r flex items-center justify-center text-center p-[1px] rounded-full cursor-pointer ${
              phrase === text.first
                ? 'from-[#FF4E83] to-[#FFBB54]'
                : 'from-white/20 to-white/20 hover:from-white/40 hover:to-white/40'
            }`}
            key={`button-${idx}`}
          >
            <div
              onClick={() =>
                setText({
                  first: phrase,
                  second: secondPhrase,
                })
              }
              className={`${
                phrase === text.first ? activeStyle : inactiveStyle
              } px-3 py-2 flex items-center space-x-2 bg-black w-full h-full rounded-full`}
            >
              <div
                style={{
                  backgroundImage: `url(${
                    phrase === text.first ? selectedIcon : icon
                  })`,
                }}
                className="w-6 h-6 bg-center bg-no-repeat bg-contain"
              />
              <span
                className={`${
                  phrase === text.first
                    ? 'text-transparent bg-clip-text bg-gradient-to-r from-[#FF4E83] to-[#FFBB54]'
                    : 'text-white/50 group-hover:text-white/70'
                } `}
              >
                {t(buttonText)}
              </span>
            </div>
          </div>
        )
      )}
    </div>
  );
}

function WorkCenterImageSection() {
  const { language } = useRecoilValue(userinfoAtom);
  return (
    <>
      <div className="flex relative w-full h-full max-2xl:w-[80%] max-md:mt-32">
        <div
          className={`swiper-animation-workcenter swing z-10 absolute ${
            language === 'kr' ? 'top-10' : 'top-4 max-2xl:top-20'
          } right-1/2`}
        >
          <img
            alt="alice card"
            className="w-56 max-2xl:w-48 max-lg:w-40"
            src="/homepage/alice-card.png"
          />
        </div>
        <div
          className={`swiper-animation-workcenter swing2 z-10 absolute ${
            language === 'kr' ? 'top-10' : 'top-4 max-2xl:top-20'
          } right-[20%] max-2xl:right-[16%]`}
        >
          <img
            alt="amber card"
            className="w-56 max-2xl:w-48 max-lg:w-40"
            src="/homepage/amber-card.png"
          />
        </div>
        <div
          className={`bg-black -rotate-[15deg] w-full h-32 max-md:h-24 absolute -top-7 ${
            language === 'kr' ? 'max-2xl:-top-8' : 'max-2xl:top-7'
          } max-lg:top-3 z-10`}
        />
        <div
          className="swiper-animation-studio fade-in-left w-full h-full absolute bg-contain bg-no-repeat bg-center"
          style={{ backgroundImage: 'url(/homepage/circle.png)' }}
        />
      </div>
    </>
  );
}

function StudioImageSection() {
  const { language } = useRecoilValue(userinfoAtom);
  return (
    <>
      <div className="flex relative w-full h-full max-2xl:w-[80%]">
        <div className="swiper-animation-studio fade-in-left-image w-[90%] max-2xl:w-[90%] z-10 absolute right-10 max-2xl:right-0 bottom-20">
          <img alt="ploonet studio image" src="/homepage/studio-section.png" />
        </div>
        <div className="absolute w-72 top-32 left-0 max-2xl:w-56 max-2xl:top-36 max-2xl:left-4 z-10 swiper-animation-studio fade-in-left-float2">
          <img
            alt="ploonet studio image"
            src={
              language === 'kr'
                ? '/homepage/studio2.png'
                : '/homepage/studio2-en.png'
            }
          />
        </div>
        <div className="absolute w-72 bottom-40 -left-10 max-2xl:w-56 max-2xl:bottom-36 max-2xl:-left-4 z-10 swiper-animation-studio fade-in-left-float">
          <img alt="ploonet studio image" src="/homepage/studio1.png" />
        </div>
        <div
          className="swiper-animation-studio fade-in-left w-full h-full absolute bg-contain bg-no-repeat bg-center"
          style={{ backgroundImage: 'url(/homepage/circle.png)' }}
        />
      </div>
    </>
  );
}

function MetahumanImageSection() {
  const { language } = useRecoilValue(userinfoAtom);
  return (
    <div className="flex relative w-full h-full max-2xl:w-[80%]">
      <div
        className={`absolute right-0 max-2xl:-right-20 bottom-[116px] ${
          language === 'kr' ? 'max-2xl:bottom-[94px]' : 'max-2xl:bottom-[60px]'
        } w-[600px] max-2xl:w-[500px] swiper-animation-studio fade-up-image z-[11] h-full`}
      >
        <img alt="ploonet metahuman image" src="/homepage/metahuman1.png" />
      </div>
      <div
        className={`absolute left-24 max-2xl:left-14 bottom-1 ${
          language === 'kr' ? 'max-2xl:-bottom-6' : 'max-2xl:-bottom-[60px]'
        } w-[370px] max-2xl:w-[300px] swiper-animation-studio fade-up-image2 z-10 h-full`}
      >
        <img alt="ploonet metahuman image" src="/homepage/metahuman2.png" />
      </div>
      <div
        className="swiper-animation-studio fade-in-left w-full h-full absolute top-2 bg-contain bg-no-repeat bg-center"
        style={{ backgroundImage: 'url(/homepage/circle.png)' }}
      />
    </div>
  );
}

function SonImageSection() {
  const { language } = useRecoilValue(userinfoAtom);

  return (
    <div className="flex relative w-full h-full max-2xl:w-[90%]">
      <div
        className={`absolute right-32 top-24 2xl:right-36 2xl:top-20 swiper-animation-studio fade-up-image z-[11] h-full`}
      >
        <img
          className="w-[420px] 2xl:w-[500px]"
          alt="ploonet metahuman image"
          src={
            language === 'en'
              ? '/homepage/pc-son-en.png'
              : '/homepage/pc-son.png'
          }
        />
      </div>
      <div
        className="swiper-animation-studio fade-in-left w-full h-full absolute top-2 bg-contain bg-no-repeat bg-center"
        style={{ backgroundImage: 'url(/homepage/circle.png)' }}
      />
    </div>
  );
}
