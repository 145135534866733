import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { assignedTelno } from '../../store';
import { isLogin } from '../../utils/common';
import { useUser } from '../hooks/use-user';
import CloseIcon from '../vector/common/CloseIcon';
import LinkIcon from '../vector/workcenter/LinkIcon';

type Props = { closeBanner: () => void; openModal: () => void };

export default function QuickStartBanner({ closeBanner, openModal }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { myInfo } = useUser();
  const setAssignedTelno = useSetRecoilState(assignedTelno);

  const handleQuickStart = () => {
    isLogin ? handleLoggedInUser() : navigate('/signup/quick/step01');
  };

  function handleLoggedInUser() {
    if (myInfo?.quickstartStateCd === 1) {
      setAssignedTelno(myInfo.assignTelno);
      navigate('/signup/quick/complete');
    } else {
      openModal();
    }
  }

  return (
    <section className="fixed top-0 right-1/2 translate-x-1/2 z-[100] w-full">
      <div className="relative flex justify-center items-center mx-auto py-4 md:py-[19px] bg-gradient-to-br from-[#FF6D51] to-[#FF9A51]">
        <div className="relative max-w-[1920px] w-full mx-auto flex justify-center">
          <div className="flex max-md:w-full max-md:flex-col max-md:space-y-3 max-md:items-start items-center max-md:px-5">
            <h1 className="text-white sm:text-lg md:text-xl 2xl:text-[28px] 2xl:leading-7 max-lg:whitespace-pre-wrap">
              <strong className="font-bold max-lg:block max-md:whitespace-pre-wrap">
                {t('10억짜리 콜센터가 클릭 한번이면 \n누구나 사용 가능! ')}
              </strong>
              {t('지금 바로 시작해 보세요.')}
            </h1>

            <button
              onClick={handleQuickStart}
              className="border border-white py-2 px-3 md:py-4 md:px-5 flex items-center space-x-3 rounded-lg group hover:bg-white/10 md:ml-10 2xl:ml-52"
            >
              <span className="font-bold text-white max-md:text-sm">
                {t('무료시작하기')}
              </span>
              <i className="group-hover:translate-x-[2px] group-hover:-translate-y-[2px] duration-100 ease-out">
                <LinkIcon />
              </i>
            </button>
          </div>
          <button
            onClick={closeBanner}
            className="absolute right-5 md:right-10 top-0 md:bottom-1/2 md:translate-y-1/2"
          >
            <CloseIcon isBig isDark />
          </button>
        </div>
      </div>
    </section>
  );
}
