import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  metahumanButtonArray,
  sonButtonArray,
  studioButtonArray,
  workcenterButtonArray,
} from '../../array/home';
import NewsSection from '../../components/home/NewsSection';
import { exQuickSignUp } from '../../services/PrivateService';
import { workcenterDomain } from '../../services/defaultClient';
import {
  assignedTelno,
  inquiryClicked,
  quickBannerAtom,
  userinfoAtom,
} from '../../store';
import { isLogin } from '../../utils/common';
import Footer from '../common/Footer';
import Loader from '../common/Loader';
import QuickstartModal from '../common/modal/QuickstartModal';
import { useUser } from '../hooks/use-user';
import ContactSection from './Contactsection';
import MobileMainImageSection from './MobileMainImageSection';
import BiFlow from './bi-flow';
import EnglishCopy from './english-copy';
import KoreanCopy from './korean-copy';

export default function MobileMainSection() {
  const [loading, setLoading] = useState(false);
  const [isClicked, setIsClicked] = useRecoilState(inquiryClicked);
  const [quickModal, setQuickModal] = useState(false);
  const { language } = useRecoilValue(userinfoAtom);
  const setAssignedTelno = useSetRecoilState(assignedTelno);
  const navigate = useNavigate();
  const { myInfo } = useUser();
  const inquiryRef = useRef(null);
  const quickBanner = useRecoilValue(quickBannerAtom);

  useEffect(() => {
    if (isClicked) {
      setTimeout(() => {
        window.scrollTo(0, 3900);
      }, 100);
      setIsClicked(false);
    }
  }, []);

  const handleQuickStart = () => {
    isLogin ? handleLoggedInUser() : navigate('/signup/quick/step01');
  };

  function handleLoggedInUser() {
    if (myInfo?.quickstartStateCd === 1) {
      setAssignedTelno(myInfo.assignTelno);
      navigate('/signup/quick/complete');
    } else {
      setQuickModal(true);
    }
  }

  const requestQuickSignUp = () => {
    setLoading(true);
    exQuickSignUp({})
      .then(({ data }) =>
        data.resultCd === '0000'
          ? handleSuccess(data.data.assignTelno)
          : handleError(String(data.data.result))
      )
      .catch(() => navigate('/signup/quick/error/-3'))
      .finally(() => setLoading(false));

    function handleSuccess(telno: string) {
      setAssignedTelno(telno);
      navigate('/signup/quick/complete');
    }

    function handleError(result: string) {
      navigate(`/signup/quick/error/${result}`);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <QuickstartModal
        isOpen={quickModal}
        onClick={requestQuickSignUp}
        closeModal={() => setQuickModal(false)}
      />
      <section
        className={`bg-black md:hidden ${
          quickBanner ? 'pt-[214px]' : 'pt-[62px]'
        }`}
      >
        <div className="w-screen h-screen bg-black relative -translate-y-12 md:translate-y-16 2xl:translate-y-[70px]">
          <div className="swiper-animation bg-black text-white font-bold flex flex-col justify-center h-screen text-center">
            {language === 'kr' ? <KoreanCopy /> : <EnglishCopy />}
            <div className="mx-auto min-w-[240px] w-2/5 h-px bg-gradient-to-r from-white/0 via-white/50 to-white/0 block mb-14 2xl:mb-20 mt-16 xl:mt-20 2xl:mt-[120px]" />
            <BiFlow inquiryRef={inquiryRef} />
          </div>
        </div>
        <section className="space-y-24">
          <div className="text-white font-bold text-4xl flex flex-col justify-center h-full">
            <MobileMainImageSection
              handleQuickStart={handleQuickStart}
              subTitle="국내 유일 옴니채널 인공지능 비서"
              title="플루닛 워크센터"
              sectionButtonArray={workcenterButtonArray}
              // buttonText="Work Center 시작하기"
              // buttonLink={workcenterDomain}
              buttonText={t('무료시작하기')}
              buttonLink={
                isLogin
                  ? 'https://workcenter.ploonet.com/quickStart/QuickStartStep1'
                  : '/signup/quick/step01'
                // 'https://workcenter.ploonet.com/quickStart/QuickStartStep1'
              }
              routeUrl="/work-center"
              secondButtonRouter="/"
              vector="AliceNameCard"
              secondVector="AmberNameCard"
              mobileVector="MobileFirstSection"
              section="workcenter"
            />
          </div>

          <div className="text-white font-bold text-4xl flex flex-col justify-center h-full">
            <MobileMainImageSection
              handleQuickStart={handleQuickStart}
              subTitle="생성형 인공지능 영상생성과 방송 플랫폼"
              title="플루닛 스튜디오"
              sectionButtonArray={studioButtonArray}
              buttonText="Studio 시작하기"
              buttonLink="https://studio.ploonet.com/main"
              routeUrl="/studio"
              secondButtonRouter="/"
              vector="studio"
              mobileVector="MobileFirstSection"
              section="studio"
            />
          </div>

          <div className="text-white font-bold text-4xl flex flex-col justify-center h-full">
            <MobileMainImageSection
              handleQuickStart={handleQuickStart}
              subTitle="맞춤형 AI 개인 비서"
              title="모바일앱 손비서"
              sectionButtonArray={sonButtonArray}
              buttonText="Work Center 바로가기"
              buttonLink={workcenterDomain}
              routeUrl="/son"
              secondButtonRouter="/"
              vector="AliceNameCard"
              secondVector="AmberNameCard"
              mobileVector="MobileFirstSection"
            />
          </div>
          <div className="text-white font-bold text-4xl flex flex-col justify-center h-full">
            <MobileMainImageSection
              handleQuickStart={handleQuickStart}
              subTitle="누구나 손쉽게 생성가능한 또 다른 나"
              title="플루닛 메타휴먼"
              sectionButtonArray={metahumanButtonArray}
              buttonText="나만의 메타휴먼 만들기"
              routeUrl="/metahuman"
              secondButtonRouter="/"
              vector="AliceNameCard"
              secondVector="AmberNameCard"
              mobileVector="MobileFirstSection"
              section="metahuman"
            />
          </div>
          <NewsSection />
          <div ref={inquiryRef}>
            <ContactSection />
          </div>
        </section>
        <Footer />
      </section>
    </>
  );
}
