import { Dialog, Transition } from '@headlessui/react';
import { t } from 'i18next';
import { Fragment, SetStateAction, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { headerNavArray } from '../../array/headerNav';
import { SSO_LOGIN_URL } from '../../constants';
import { exQuickSignUp } from '../../services/PrivateService';
import ssoApi from '../../services/apiSso';
import AuthService from '../../services/auth.service';
import TokenService from '../../services/token.service';
import {
  assignedTelno,
  clicked,
  dark,
  quickBannerAtom,
  quickRegisterPersonalInfo,
  slideIndex,
  userinfoAtom,
} from '../../store';
import { isLogin } from '../../utils/common';
import QuickStartBanner from '../banner/quick-start-banner';
import { useUser } from '../hooks/use-user';
import LoginPage from '../index/LoginPage';
import CloseIcon from '../vector/common/CloseIcon';
import MainLogo from '../vector/header/MainLogo';
import BurgerIcon from '../vector/header/MobileHamburger';
import MobileMainLogo from '../vector/header/MobileLogo';
import DotIcon from '../vector/payment-details/DotIcon';
import LanguageSelectBox from './LanguageSelectBox';
import Loader from './Loader';
import TopButton from './TopButton';
import InformModal from './modal/InformModal';
import MainBanner from './modal/MainBanner';
import QuickstartModal from './modal/QuickstartModal';

const screenSize = window.innerWidth;

export default function Header() {
  const [loginPage, setLoginPage] = useState(false);
  const [banner, setBanner] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [isDark, setIsDark] = useRecoilState(dark);
  const [linkClicked, setLinkClicked] = useRecoilState(clicked);
  const [informModal, setInformModal] = useState(false);
  const setIndex = useSetRecoilState(slideIndex);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isMain, setIsMain] = useState(false);
  const [quickBanner, setQuickBanner] = useRecoilState(quickBannerAtom);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const setAssignedTelno = useSetRecoilState(assignedTelno);
  const setRegisterInfo = useSetRecoilState(quickRegisterPersonalInfo);
  const { myInfo } = useUser();

  useEffect(() => {
    sessionStorage.setItem('prevPath', document.location.href);
    if (
      !isLogin &&
      window.location.href.includes('https://') &&
      !sessionStorage.getItem('ssoLoginCheck')
    ) {
      document.location.href = SSO_LOGIN_URL;
    }
    if (TokenService.getLocalSsoToken()) {
      const checkSso = setInterval(() => ssoCheck(), 10000);
      return () => clearInterval(checkSso);
    }
  }, []);

  useEffect(() => {
    if (
      loginPage ||
      pathname.includes('/my-page') ||
      pathname.includes('/login') ||
      pathname.includes('/signup') ||
      pathname.includes('/about') ||
      pathname.includes('/help')
    ) {
      setIsDark(false);
    } else {
      setIsDark(true);
    }
    pathname === '/' ? setIsMain(true) : setIsMain(false);
  }, [pathname, loginPage, linkClicked]);

  const ssoCheck = () => {
    const req = {
      id_token: TokenService.getLocalSsoToken(),
      iscltapi: 'Y',
    };

    ssoApi
      .post('/api/sso/login/check', req)
      .then((res) => {
        const resData = res.data;
        resData.success &&
          resData.statusCode === '200' &&
          !resData.dataMap.valid &&
          setInformModal(true);
      })
      .catch(console.log);
  };

  const logOut = () => {
    const req = {
      id_token: TokenService.getLocalSsoToken(),
      iscltapi: 'Y',
    };

    ssoApi.post('/api/sso/logout', req).catch(console.log);
    AuthService.logout();
    window.location.href = '/';
  };

  const handleQuickStart = () => {
    setLoading(true);
    exQuickSignUp({})
      .then(({ data }) =>
        data.resultCd === '0000'
          ? handleSuccess(data.data.assignTelno)
          : handleError(String(data.data.result))
      )
      .catch(() => navigate('/signup/quick/error/-3'))
      .finally(() => setLoading(false));

    function handleSuccess(telno: string) {
      setAssignedTelno(telno);
      setRegisterInfo((prev) => ({
        ...prev,
        memberFnm1: myInfo.memberFnm1 || myInfo.managerFnm,
      }));
      navigate('/signup/quick/complete');
    }

    function handleError(result: string) {
      navigate(`/signup/quick/error/${result}`);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <TopButton />
      <InformModal
        phrase="로그아웃되었습니다."
        modal={informModal}
        closeModal={() => {
          setInformModal(false);
          logOut();
        }}
      />
      <QuickstartModal
        isOpen={modal}
        onClick={handleQuickStart}
        closeModal={() => setModal(false)}
      />
      <main className="flex flex-col space-y-24 max-md:space-y-0">
        {isMain && <MainBanner banner={banner} setBanner={setBanner} />}
        {isMain && quickBanner && (
          <QuickStartBanner
            openModal={() => setModal(true)}
            closeBanner={() => setQuickBanner(false)}
          />
        )}
        <header
          className={`py-10 border-b z-20 max-md:hidden fixed top-0 w-screen px-5 ${
            isDark ? 'border-black bg-black' : 'border-borderGray bg-white'
          }`}
        >
          <MobileMenu
            logOut={logOut}
            isDark={isDark}
            pathName={pathname}
            mobileMenu={mobileMenu}
            closeModal={() => setMobileMenu(false)}
          />

          <LoginPage
            quickBanner={isMain && quickBanner}
            banner={banner}
            loginPage={loginPage}
            closeModal={() => setLoginPage(false)}
          />
          <div className="flex items-center justify-between max-w-[1600px] max-2xl:max-w-[1200px] mx-auto">
            <Link to="/">
              <i onClick={() => setIndex(0)} className="cursor-pointer">
                <MainLogo isDark={isDark} />
              </i>
            </Link>

            <nav>
              <ul className="flex md:space-x-6 xl:space-x-12 2xl:space-x-20">
                {headerNavArray.map(({ menu, link }, idx) => (
                  <li
                    key={`menuList-${idx}`}
                    onClick={() => setLinkClicked(!linkClicked)}
                    className={`text-xl max-2xl:text-base max-lg:text-sm font-medium cursor-pointer ${
                      isDark ? 'hover:text-white' : 'hover:text-black'
                    } duration-100 ease-out relative 
                  ${
                    pathname === link
                      ? `${isDark ? 'text-white' : 'text-black'}`
                      : 'text-textGray'
                  }
                  `}
                  >
                    <Link to={link}>{t(menu)}</Link>
                    {pathname === link && (
                      <div className="absolute top-9 right-1/2 translate-x-1/2 w-2 h-2 rounded-full bg-gradient-to-br from-[#FF4E83] to-[#FFBB54]" />
                    )}
                  </li>
                ))}
                <Link to="/">
                  <li
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setIndex(6);
                    }}
                    className={`${
                      isDark ? 'hover:text-white' : 'hover:text-black'
                    } duration-100 ease-out text-textGray text-xl max-2xl:text-base max-lg:text-sm font-medium cursor-pointer`}
                  >
                    {t('제휴문의')}
                  </li>
                </Link>
              </ul>
            </nav>
            <div className="flex items-center space-x-3 max-2xl:space-x-2 cursor-pointer relative max-2xl:text-sm">
              <button
                disabled={pathname.includes('/login')}
                onClick={() =>
                  isLogin
                    ? navigate(
                        screenSize < 769 ? '/my-page' : '/my-page/membership'
                      )
                    : setLoginPage(true)
                }
                className={`${
                  isDark ? 'text-white' : 'text-black'
                } hover:text-[#FF7B51] duration-100 ease-out font-bold`}
              >
                {isLogin ? 'Mypage' : t('로그인')}
              </button>
              {isLogin && (
                <>
                  <i>
                    <DotIcon />
                  </i>
                  <button
                    onClick={logOut}
                    className="text-black5 hover:text-[#FF7B51] duration-100 ease-out font-bold"
                  >
                    {t('로그아웃')}
                  </button>
                </>
              )}

              <LanguageSelectBox
                clickEvent={() => setLinkClicked(!linkClicked)}
                isDark={isDark}
              />
            </div>
          </div>
        </header>
        <header
          className={`py-4 px-5 border-b bg-white z-50 border-borderGray md:hidden fixed top-0 w-full overflow-hidden ${
            isDark && '!bg-black border-none'
          } ${isMain && quickBanner && 'top-[154px]'}`}
        >
          <div className="flex items-center w-full justify-between">
            <Link to="/">
              <button>
                <MobileMainLogo isDark={isDark} />
              </button>
            </Link>
            <div className="flex items-center space-x-3">
              <button onClick={() => setMobileMenu(true)}>
                <BurgerIcon isDark={isDark} />
              </button>
            </div>
          </div>
        </header>
      </main>
    </>
  );
}

function MobileMenu({
  closeModal,
  mobileMenu,
  pathName,
  isDark,
  logOut,
}: {
  closeModal: React.Dispatch<SetStateAction<boolean>>;
  mobileMenu: boolean;
  pathName?: string;
  isDark: boolean;
  logOut: () => void;
}) {
  const navigate = useNavigate();
  const { language } = useRecoilValue(userinfoAtom);

  return (
    <Transition appear show={mobileMenu} as={Fragment}>
      <Dialog
        className="mobile-menu"
        as="div"
        onClose={() => closeModal(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="absolute top-0 z-[110] w-full h-full bg-black/25" />
        </Transition.Child>

        <div className="absolute z-[110] top-0 inset-0 overflow-y-hidden">
          <div className="flex text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-x-40"
              enterTo="opacity-100 scale-100 translate-x-0"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 scale-100 translate-x-0"
              leaveTo="opacity-0 scale-100 translate-x-40"
            >
              <Dialog.Panel
                className={`absolute w-[90%] top-0 h-screen right-0 transform overflow-hidden ${
                  isDark ? 'bg-black' : 'bg-white'
                } transition-all`}
              >
                <div className="text-center">
                  <header
                    className={`${
                      isDark ? 'text-white' : 'text-black'
                    } font-bold flex items-center justify-between px-5 pt-5 fixed top-0 w-full`}
                  >
                    <section className="space-x-2 flex items-center">
                      <button
                        onClick={() => {
                          if (isLogin) {
                            closeModal(false);
                            navigate('/my-page');
                          } else {
                            closeModal(false);
                            navigate('/login');
                          }
                        }}
                      >
                        {isLogin ? 'Mypage' : 'SIGN IN'}
                      </button>
                      {isLogin && (
                        <>
                          <i>
                            <DotIcon />
                          </i>
                          <button
                            onClick={logOut}
                            className="text-black5 hover:text-[#FF7B51] duration-100 ease-out font-bold"
                          >
                            {language === 'kr' ? '로그아웃' : 'Logout'}
                          </button>
                        </>
                      )}
                    </section>
                    <section className="space-x-5 flex">
                      <LanguageSelectBox isDark={isDark} />
                      <button onClick={() => closeModal(false)}>
                        <CloseIcon isDark={isDark} />
                      </button>
                    </section>
                  </header>
                  <nav className="px-10 mt-32">
                    <ul className="flex flex-col space-y-12">
                      {headerNavArray.map(({ menu, link }, idx) => (
                        <li
                          onClick={() => {
                            navigate(link);
                            closeModal(false);
                          }}
                          key={`menuList-${idx}`}
                          className={`text-2xl font-bold cursor-pointer hover:text-[#ff7a51b6] duration-100 ease-out w-fit relative ${
                            pathName === link
                              ? `${isDark ? 'text-white' : 'text-black'}`
                              : 'text-textGray'
                          }`}
                        >
                          {t(menu)}
                          {pathName === link && (
                            <div className="absolute bottom-1/2 -right-5 translate-y-1/2 w-2 h-2 rounded-full bg-gradient-to-br from-[#FF4E83] to-[#FFBB54]" />
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
