import { Listbox, Transition } from '@headlessui/react';
import { Fragment, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PartnershipAddReqDtoInf } from '../../services/dto/PartnershipDtoInf';
import ChevronDownIcon from '../vector/payment-details/ChevronDownIcon';

const serviceTypeArray: { name: string; value: string }[] = [
  { name: '서비스 선택', value: '' },
  { name: '플루닛', value: 'B15' },
  { name: '스튜디오', value: 'B13' },
  { name: '워크센터', value: 'B11' },
  { name: '손비서앱', value: 'B14' },
  { name: '메타휴먼', value: 'B12' },
  { name: '기타', value: 'etc' },
];

export default function InquiryTypeSelectBox({
  formData,
  setFormData,
}: {
  formData: PartnershipAddReqDtoInf;
  setFormData: React.Dispatch<SetStateAction<PartnershipAddReqDtoInf>>;
}) {
  const [selected, setSelected] = useState(serviceTypeArray[0]);
  const { t } = useTranslation();

  useEffect(() => {
    !formData.inquiryTit && setSelected(serviceTypeArray[0]);
  }, [formData.inquiryTit]);

  return (
    <div className="border-b pb-4 max-md:pt-4 max-md:pb-3 border-white/20 hover:border-white/60 duration-300 ease-out w-full max-md:w-full">
      <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <div className="relative mt-1">
            <Listbox.Button
              className={`${
                selected !== serviceTypeArray[0] ? 'text-white' : 'text-[#999]'
              } relative w-full rounded-lg bg-transparent text-left focus:outline-none text-xl max-md:text-base cursor-pointer`}
            >
              <span className="block truncate">{t(selected.name)}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                <i
                  className={`${
                    open ? 'rotate-180' : ''
                  } duration-300 ease-out`}
                >
                  <ChevronDownIcon isMain />
                </i>
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="border border-gray2/20 absolute mt-6 w-full rounded-md bg-[#111111] px-1 py-2 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-sm:text-sm">
                {serviceTypeArray.map((list, idx) => (
                  <Listbox.Option
                    key={`list-${idx}`}
                    onClick={() => {
                      list.value &&
                        setFormData((prev) => ({
                          ...prev,
                          inquiryTit: list.name,
                        }));
                    }}
                    className="relative py-1 px-4 text-white/40 cursor-pointer bg-[#111111] hover:text-[#FF7B51]"
                    value={list}
                  >
                    {({ selected }) => (
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium text-primary' : 'font-normal'
                        }`}
                      >
                        {t(list.name)}
                      </span>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  );
}
