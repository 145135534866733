import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import { RecoilEnv, RecoilRoot } from 'recoil';
import Header from './components/common/Header';
import ScrollToTop from './components/common/ScrollToTop';
import OAuth2Redirect from './components/oauth2/OAuth2Redirect';
import OutletDefault from './components/outlet/OutletDefault';
import OutletPopup from './components/outlet/OutletPopup';
import NotFound from './components/route/NotFound';
import PrivateRoute from './components/route/PrivateRoute';
import PublicRoute from './components/route/PublicRoute';
import SnsRedirect from './components/sso/SnsRedirect';
import SsoRedirect from './components/sso/SsoRedirect';
import About from './views/about';
import Dormant from './views/help/dormant';
import FindId from './views/help/find-id';
import FindPw from './views/help/find-pw';
import PrivacyPolicy from './views/help/privacy-policy';
import TermsOfUse from './views/help/terms-use';
import TotalInfo from './views/help/total-info';
import Home from './views/home';
import Login from './views/login';
import MyPage from './views/mypage';
import CreditDetails from './views/mypage/credits';
import Inquiry from './views/mypage/inquiry';
import RequestInquiry from './views/mypage/inquiry/req';
import Membership from './views/mypage/membership';
import MyInfo from './views/mypage/my-info';
import PaymentDetails from './views/mypage/payments';
import ServiceDetails from './views/mypage/service-usage';
import SignUp from './views/signup';
import SignupComplete from './views/signup/complete';
import QuickSignupComplete from './views/signup/quick/complete';
import QuickSignupError from './views/signup/quick/error';
import QuickSignupPage from './views/signup/quick/step01';
import QuickSignupStepTwo from './views/signup/quick/step02';
import RegCertPopup from './views/signup/regcert/RegCertPopup';
import RegCertResult from './views/signup/regcert/RegCertResult';
import SignUpRegisterInfo from './views/signup/step02';
import SignUpRegisterInfoForeigner from './views/signup/step02-en';
import EnterpriseSignUpRegisterInfo from './views/signup/step02-ep';
import CharacterSelect from './views/signup/step03';
import Metahuman from './views/subpage/metahuman';
import Notice from './views/subpage/notice';
import NoticeDetail from './views/subpage/notice/detail';
import SonPage from './views/subpage/son';
import Studio from './views/subpage/studio';
import WorkCenter from './views/subpage/workcenter';

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

function App() {
  return (
    <>
      <Helmet>
        <title>플루닛 – 국내 최고 생성형 AI 서비스 전문 기업</title>
        <meta charSet="UTF-8" />
        <meta
          id="description"
          name="description"
          content="플루닛은 생성형, 대화형 인공지능 기술 기반의 다양한 플랫폼과 서비스를 제공하는 국내 1위 생성형 AI 서비스 전문 기업입니다."
        />
        <meta
          name="keywords"
          content="플루닛, 생성형 AI, 생성형 인공지능, 생성적 인공지능, 인공지능 비서, GPT, AI 서비스 전문 기업"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site" content="http://www.ploonet.com" />
        <meta
          property="og:site_name"
          content="플루닛  – 국내 최고 생성형 AI 서비스 전문 기업"
        />
        <meta
          id="ogTitle"
          property="og:title"
          content="플루닛  – 국내 최고 생성형 AI 서비스 전문 기업"
        />
        <meta
          id="ogDescription"
          property="og:description"
          content="플루닛, 생성형 AI, 생성형 인공지능, AI 비서, 인공지능 비서, 워크센터, 스튜디오, GPT"
        />
      </Helmet>
      <RecoilRoot>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Home />
                </>
              }
            />
            <Route path="/" element={<OutletDefault />}>
              <Route path="help/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="help/terms-use" element={<TermsOfUse />} />
              <Route path="help/total-info" element={<TotalInfo />} />
              <Route path="notice" element={<Notice />} />
              <Route path="notice/:id" element={<NoticeDetail />} />
              {/* <Route path="plan" element={<Plan />} /> */}
              <Route path="work-center" element={<WorkCenter />} />
              <Route path="studio" element={<Studio />} />
              <Route path="metahuman" element={<Metahuman />} />
              <Route path="son" element={<SonPage />} />
              <Route path="about" element={<About />} />
              <Route
                path="signup/quick/complete"
                element={<QuickSignupComplete />}
              />
              <Route
                path="signup/quick/error/:code"
                element={<QuickSignupError />}
              />
              {/* Only Public */}
              <Route element={<PublicRoute />}>
                <Route path="login" element={<Login />} />
                <Route path="help/find-pw" element={<FindPw />} />
                <Route path="help/find-id" element={<FindId />} />
                <Route path="help/dormant" element={<Dormant />} />
                <Route path="signup" element={<SignUp />} />
                <Route path="signup/step02" element={<SignUpRegisterInfo />} />
                <Route
                  path="signup/step02-en"
                  element={<SignUpRegisterInfoForeigner />}
                />
                <Route
                  path="signup/step02-ep"
                  element={<EnterpriseSignUpRegisterInfo />}
                />
                <Route path="signup/step03" element={<CharacterSelect />} />
                <Route path="signup/complete" element={<SignupComplete />} />
                <Route
                  path="signup/quick/step01"
                  element={<QuickSignupPage />}
                />
                <Route
                  path="signup/quick/step02"
                  element={<QuickSignupStepTwo />}
                />
              </Route>
              {/* Only Private */}
              <Route element={<PrivateRoute />}>
                <Route path="my-page" element={<MyPage />} />
                <Route path="my-page/membership" element={<Membership />} />
                <Route path="my-page/payments" element={<PaymentDetails />} />
                <Route path="my-page/credits" element={<CreditDetails />} />
                <Route
                  path="my-page/service-usage"
                  element={<ServiceDetails />}
                />
                {/* <Route path="my-page/pay-method" element={<PayMethod />} /> */}
                <Route path="my-page/inquiry" element={<Inquiry />} />
                <Route
                  path="my-page/inquiry/req"
                  element={<RequestInquiry />}
                />
                <Route path="my-page/my-info" element={<MyInfo />} />
              </Route>
              <Route path="oauth2/redirect" element={<OAuth2Redirect />} />
            </Route>
            <Route path="/popup/:path?" element={<OutletPopup />}>
              <Route path="signup/regcert" element={<RegCertPopup />} />
              <Route path="signup/regcertresult" element={<RegCertResult />} />
            </Route>
            <Route path="/sso/redirect" element={<SsoRedirect />}></Route>
            <Route path="/sns/redirect" element={<SnsRedirect />}></Route>
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </RecoilRoot>
    </>
  );
}

export default App;
