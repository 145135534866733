export const floatingNavArray = [
  {
    label: '워크센터',
    index: 1,
  },
  {
    label: '스튜디오',
    index: 2,
  },
  {
    label: '손비서',
    index: 3,
  },
  {
    label: '메타휴먼',
    index: 4,
  },
];

export const biFlowArray = [
  '/homepage/bi/nhn.png',
  '/homepage/bi/diquest.png',
  '/homepage/bi/sk.png',
  '/homepage/bi/jeju.png',
  '/homepage/bi/db.png',
  '/homepage/bi/db-life.png',
  '/homepage/bi/kb.png',
  '/homepage/bi/optus.png',
  '/homepage/bi/hyundai.png',
  '/homepage/bi/ebc.png',
  '/homepage/bi/lh.png',
  '/homepage/bi/multicampus.png',
  '/homepage/bi/katech.png',
  '/homepage/bi/tongin.png',
  '/homepage/bi/hancom.png',
  '/homepage/bi/bngsteel.png',
  '/homepage/bi/miilk.png',
  '/homepage/bi/nhn.png',
  '/homepage/bi/diquest.png',
  '/homepage/bi/sk.png',
  '/homepage/bi/jeju.png',
  '/homepage/bi/db.png',
  '/homepage/bi/db-life.png',
  '/homepage/bi/kb.png',
  '/homepage/bi/optus.png',
  '/homepage/bi/hyundai.png',
  '/homepage/bi/ebc.png',
  '/homepage/bi/lh.png',
  '/homepage/bi/multicampus.png',
  '/homepage/bi/katech.png',
  '/homepage/bi/tongin.png',
  '/homepage/bi/hancom.png',
  '/homepage/bi/bngsteel.png',
  '/homepage/bi/miilk.png',
];

const commonIconPath = '/homepage/icons/';
export const workcenterButtonArray = [
  {
    buttonText: '리셉셔니스트',
    phrase: '방문과 전화 시 회사의 기본 정보와 부서별 안내 담당자 연결',
    icon: `${commonIconPath}recep.png`,
    selectedIcon: `${commonIconPath}recep_active.png`,
  },
  {
    buttonText: '장애접수안내',
    phrase: '서비스 장애 발생 시 긴급 문의와 안내, 담당자 연결과 메모 전달',
    icon: `${commonIconPath}error.png`,
    selectedIcon: `${commonIconPath}error_active.png`,
  },
  {
    buttonText: '경영지원',
    phrase: '채용과 복지 안내, 각종 문서 발급을 돕는 경영지원',
    icon: `${commonIconPath}support.png`,
    selectedIcon: `${commonIconPath}support_active.png`,
  },
  {
    buttonText: '영업/텔레마케터',
    phrase: '방문 접수/예약을 도와주고 유용한 프로모션과 마케팅을 위한 지원',
    icon: `${commonIconPath}marketer.png`,
    selectedIcon: `${commonIconPath}marketer_active.png`,
  },
  {
    buttonText: '개인비서',
    phrase: '회의 중, 부재 중일 때 대신 전화응대와 메모를 전달해주는 비서',
    icon: `${commonIconPath}secretary.png`,
    selectedIcon: `${commonIconPath}secretary_active.png`,
  },
];

export const studioButtonArray = [
  {
    buttonText: '제작 시간/비용 절감',
    phrase: '스몰 데이터 무제한 음성학습과 합성으로 제작 시간 1/6,',
    secondPhrase: '유명 인플루언서 출연비 1/20 절감',
    icon: `${commonIconPath}decrease.png`,
    selectedIcon: `${commonIconPath}decrease_active.png`,
  },

  {
    buttonText: '실시간사용',
    phrase: '세계 유일 타임라인 편집기로 별도 설치 없이 웹에서',
    secondPhrase: '바로 사용 가능한 음향, 배경 콘텐츠로 영상 편집과 생성, 송출',
    icon: `${commonIconPath}realtime.png`,
    selectedIcon: `${commonIconPath}realtime_active.png`,
  },

  {
    buttonText: '전문가수준',
    phrase: '8K 고화질 디지털 휴먼 생성과 한국어 텍스트 입력만으로',
    secondPhrase: '36개 나라 다국어 자동 번역과 더빙 방송 가능',
    icon: `${commonIconPath}pro.png`,
    selectedIcon: `${commonIconPath}pro_active.png`,
  },

  {
    buttonText: '옴니채널연계',
    phrase: 'AR/VR을 넘어 키오스크, 모바일, 웹 사이트 및 챗봇까지',
    secondPhrase: '물리 세계에서 활동과 함께 다양한 미디어와도 연계',
    icon: `${commonIconPath}omni.png`,
    selectedIcon: `${commonIconPath}omni_active.png`,
  },
];

export const metahumanButtonArray = [
  {
    buttonText: '리셉셔니스트',
    phrase: '사용자의 의미를 인식하고 상황을 이해하여 멀티모달',
    secondPhrase: '상호작용이 가능한 AI 서비스로 몰입감 있는 사용자 경험 제공',
    icon: `${commonIconPath}recep.png`,
    selectedIcon: `${commonIconPath}recep_active.png`,
  },
  {
    buttonText: '나만의 메타휴먼',
    phrase: '나의 모습과 목소리 그대로, 언제 어디서나',
    secondPhrase: '나를 대신 하는 나를 닮은 인공지능 가상인간 제작 가능',
    icon: `${commonIconPath}metahuman.png`,
    selectedIcon: `${commonIconPath}metahuman_active.png`,
  },
];

export const sonButtonArray = [
  {
    buttonText: '비서채용',
    phrase: '12명의 캐릭터 중 나에게 맞는 AI 비서를 선택하고,',
    secondPhrase: '실시간으로 나의 정보를 학습 시켜 채용 가능',
    icon: `${commonIconPath}name_card.png`,
    selectedIcon: `${commonIconPath}name_card_active.png`,
  },
  {
    buttonText: '음성명령',
    phrase:
      '음성으로 앱 기본 기능 동작 가능 및 루시아 GPT를 통한 자유 대화 기능 제공',
    icon: `${commonIconPath}wave.png`,
    selectedIcon: `${commonIconPath}wave_active.png`,
  },
  {
    buttonText: '통화내용검색',
    phrase: '기억이 나지 않는 통화 내용과 부재메시지는 검색 기능을 통해',
    secondPhrase: '구간 듣기 및 다운로드',
    icon: `${commonIconPath}magnifier.png`,
    selectedIcon: `${commonIconPath}magnifier_active.png`,
  },
  {
    buttonText: '실시간통화녹음',
    phrase: '통화 내용은 실시간으로 녹음되며 텍스트로도 변환되어 확인',
    icon: `${commonIconPath}record.png`,
    selectedIcon: `${commonIconPath}record_active.png`,
  },
  {
    buttonText: '고객메모',
    phrase: '부재 전화 용건을 확인하고 문자 또는 음성으로 답장',
    icon: `${commonIconPath}memo.png`,
    selectedIcon: `${commonIconPath}memo_active.png`,
  },
];
