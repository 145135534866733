export const headerNavArray = [
  {
    menu: '스튜디오',
    link: '/studio',
  },
  {
    menu: '워크센터',
    link: '/work-center',
  },
  {
    menu: '손비서',
    link: '/son',
  },
  {
    menu: '메타휴먼',
    link: '/metahuman',
  },
  // {
  //   menu: '가격안내',
  //   link: '/plan',
  // },
  {
    menu: '공지사항',
    link: '/notice',
  },
];
